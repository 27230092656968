.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 18px 0;
  position: absolute;
  @include tablet {
    padding: 24px 0;
  }
  &--wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include container();
  }

  &__logo {
    display: block;
    max-width: 130px;
    @include tablet {
      max-width: 150px;
    }
    img {
      width: 100%;
    }
  }

  &__lang {
    margin-top: -10px;
    a {
      color: #fff;
      font-size: 18px;
      width: 46px;
      height: 30px;
      display: flex;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

