@charset "UTF-8";
/* Base */ /* Reset and base styles  */
* {
  padding: 0px;
  margin: 0px;
  border: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Links */
a, a:link, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Common */
aside, nav, footer, header, section, main {
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: inherit;
  font-weight: inherit;
}

ul, ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img, svg {
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

/* Form */
input, textarea, button, select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

input::-ms-clear {
  display: none;
}

button, input[type=submit] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
}

legend {
  display: block;
}

:root {
  --container-width: 1200px;
  --container-padding: 15px;
  --font-main: "Inter", sans-serif;
  --color-gold: #FFCC80;
  --color-grey: #7D7D7D;
  --color-brown: #5B2910;
  --page-bg: #000;
  --text-color: #000;
  --laptop-size: 1199px;
  --tablet-size: 959px;
  --mobile-size: 599px;
}

html {
  overflow-x: hidden;
  background-color: #fff;
  scroll-behavior: smooth;
  font-family: var(--font-main);
}

body {
  background: #fff;
  overflow-x: hidden;
  font-family: var(--font-main);
}

.container {
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    padding: 0 24px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 0 15px;
    max-width: 1100px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

img {
  display: block;
}

a {
  color: var(--link-color);
}

.none {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

/* Blocks */
.footer {
  z-index: 2;
  padding: 40px 0;
  margin-top: -2px;
  position: relative;
  background: #00BF8B;
}
.footer--wrapper {
  width: 100%;
  display: flex;
  row-gap: 40px;
  margin: 0 auto;
  column-gap: 20px;
  padding: 0 15px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .footer--wrapper {
    row-gap: 20px;
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
  }
}
@media (min-width: 1400px) {
  .footer--wrapper {
    width: 80%;
    max-width: 1200px;
  }
}
.footer__logo {
  max-width: 160px;
}
@media (min-width: 768px) {
  .footer__logo {
    max-width: 200px;
  }
}
.footer__logo img {
  width: 100%;
}
.footer__text p {
  color: #000;
  font-size: 12px;
  margin-bottom: 2px;
}
@media (min-width: 768px) {
  .footer__text p {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  .footer__text p {
    font-size: 14px;
  }
}
.footer__text p br {
  display: none;
}
@media (min-width: 992px) {
  .footer__text p br {
    display: block;
  }
}

.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 18px 0;
  position: absolute;
}
@media (min-width: 768px) {
  .header {
    padding: 24px 0;
  }
}
.header--wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
}
@media (min-width: 768px) {
  .header--wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .header--wrapper {
    padding: 0 24px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .header--wrapper {
    padding: 0 15px;
    max-width: 1100px;
  }
}
@media (min-width: 1400px) {
  .header--wrapper {
    max-width: 1300px;
  }
}
.header__logo {
  display: block;
  max-width: 130px;
}
@media (min-width: 768px) {
  .header__logo {
    max-width: 150px;
  }
}
.header__logo img {
  width: 100%;
}
.header__lang {
  margin-top: -10px;
}
.header__lang a {
  color: #fff;
  font-size: 18px;
  width: 46px;
  height: 30px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
}

.page {
  background: #00BF8B;
}

.kv {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  background: url("/img/bg.jpg") no-repeat center top;
  background-size: 762px;
}
@media (min-width: 768px) {
  .kv {
    background-size: contain;
  }
}
.kv__container {
  display: flex;
  row-gap: 40px;
  padding-top: 20%;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .kv__container {
    row-gap: 40px;
    padding-top: 10%;
  }
}
@media (min-width: 992px) {
  .kv__container {
    padding-top: 8%;
  }
}
@media (min-width: 1400px) {
  .kv__container {
    padding-top: 5%;
  }
}
.kv__bottom {
  padding: 0 15px;
}
.kv__title {
  width: 95%;
}
@media (min-width: 768px) {
  .kv__title {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .kv__title {
    width: 65%;
  }
}
@media (min-width: 1200px) {
  .kv__title {
    width: 65%;
  }
}
@media (min-width: 1400px) {
  .kv__title {
    width: 60%;
  }
}
.kv__product {
  width: 220%;
  max-width: none;
  margin-left: -60%;
}
@media (min-width: 768px) {
  .kv__product {
    width: 140%;
    margin-left: -20%;
  }
}
@media (min-width: 992px) {
  .kv__product {
    width: 120%;
    margin-left: -10%;
  }
}
@media (min-width: 1200px) {
  .kv__product {
    width: 110%;
    margin-left: -5%;
  }
}
@media (min-width: 1400px) {
  .kv__product {
    width: 100%;
    margin-left: 0;
  }
}

.second {
  position: relative;
}
.second__container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  position: absolute;
}
.second__title {
  top: 30%;
  left: 50%;
  width: 60%;
  position: absolute;
  transform: translateY(-30%) translateX(-80%);
}
@media (min-width: 768px) {
  .second__title {
    width: 45%;
    transform: translateY(-30%) translateX(-90%);
  }
}

.third {
  padding: 30px 15px;
  background: #E10783;
}
@media (min-width: 768px) {
  .third {
    padding: 48px 15px;
  }
}
.third__container {
  margin: 0 auto;
  max-width: 1000px;
  text-align: center;
}
.third__container p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .third__container p {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .third__container p {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .third__container p {
    font-size: 20px;
  }
}
@media (min-width: 1400px) {
  .third__container p {
    font-size: 22px;
  }
}
.third__container p:last-child {
  margin-bottom: 0;
}

.four {
  padding: 40px 15px;
  background: #fff;
}
@media (min-width: 768px) {
  .four {
    padding: 60px 15px;
  }
}
@media (min-width: 992px) {
  .four {
    padding: 80px 15px;
  }
}
.four__container {
  display: flex;
  row-gap: 60px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
}
.four__items {
  gap: 60px;
  display: grid;
}
@media (min-width: 768px) {
  .four__items {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
}
.four__item {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .four__item {
    row-gap: 40px;
  }
}
.four__item--title {
  text-align: center;
}
h2 {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 19px;
  }
}
@media (min-width: 1400px) {
  h2 {
    font-size: 22px;
  }
}

ul li {
  font-size: 13px;
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  ul li {
    font-size: 16px;
    padding-left: 24px;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  ul li {
    font-size: 18px;
  }
}
@media (min-width: 1200px) {
  ul li {
    font-size: 18px;
  }
}
@media (min-width: 1400px) {
  ul li {
    font-size: 20px;
  }
}
ul li:last-child {
  margin-bottom: 0;
}
ul li:after {
  content: "";
  top: 7px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  background: #E10783;
}
@media (min-width: 768px) {
  ul li:after {
    top: 10px;
    width: 6px;
    height: 6px;
  }
}
@media (min-width: 992px) {
  ul li:after {
    top: 10px;
    width: 8px;
    height: 8px;
  }
}
@media (min-width: 1200px) {
  ul li:after {
    top: 8px;
  }
}
@media (min-width: 1400px) {
  ul li:after {
    top: 10px;
  }
}

p {
  font-size: 13px;
}
@media (min-width: 768px) {
  p {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  p {
    font-size: 18px;
  }
}
@media (min-width: 1400px) {
  p {
    font-size: 20px;
  }
}

.video {
  width: 100%;
  position: relative;
}
.video img {
  width: 100%;
  z-index: 10;
  cursor: pointer;
  position: relative;
  transition: all 0.4s ease-in;
}
.video img.isHidden {
  opacity: 0;
  z-index: 0;
  transition: all 0.4s ease-in;
}
.video video {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* No styles code below. Only in modules */
/* Не пишите CSS код ниже. Только в подключаемых файлах */