.page {
  background: #00BF8B;
}

.kv {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;
  background: url("/img/bg.jpg") no-repeat center top;
  background-size: 762px;
  @include tablet {
    background-size: contain;
  }
  &__container {
    display: flex;
    row-gap: 40px;
    padding-top: 20%;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      row-gap: 40px;
      padding-top: 10%;
    }
    @include desktop {
      padding-top: 8%;
    }
    @include xwide {
      padding-top: 5%;
    }
  }

  &__bottom {
    padding: 0 15px;
  }

  &__title {
    width: 95%;
    @include tablet {
      width: 70%;
    }
    @include desktop {
      width: 65%;
    }
    @include wide {
      width: 65%
    }
    @include xwide {
      width: 60%;
    }
  }
  &__product {
    width: 220%;
    max-width: none;
    margin-left: -60%;
    @include tablet {
      width: 140%;
      margin-left: -20%;
    }
    @include desktop {
      width: 120%;
      margin-left: -10%;
    }
    @include wide {
      width: 110%;
      margin-left: -5%;
    }
    @include xwide {
      width: 100%;
      margin-left: 0;
    }
  }
}

.second {
  position: relative;
  &__container {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    position: absolute;
  }
  &__title {
    top: 30%;
    left: 50%;
    width: 60%;
    position: absolute;
    transform: translateY(-30%) translateX(-80%);
    @include tablet {
      width: 45%;
      transform: translateY(-30%) translateX(-90%);
    }
  }
}

.third {
  padding: 30px 15px;
  background: #E10783;
  @include tablet {
    padding: 48px 15px;
  }
  &__container {
    margin: 0 auto;
    max-width: 1000px;
    text-align: center;
    p {
      color: #fff;
      font-size: 14px;
      margin-bottom: 16px;
      @include tablet {
        font-size: 18px;
        margin-bottom: 20px;
      }
      @include desktop {
        font-size: 18px;
      }
      @include wide {
        font-size: 20px;
      }
      @include xwide {
        font-size: 22px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.four {
  padding: 40px 15px;
  background: #fff;
  @include tablet {
    padding: 60px 15px;
  }
  @include desktop {
    padding: 80px 15px;
  }
  &__container {
    display: flex;
    row-gap: 60px;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
  }

  &__items {
    gap: 60px;
    display: grid;
    @include tablet {
      gap: 40px;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    @include tablet {
      row-gap: 40px;
    }
    &--title {
      text-align: center;
    }
    &--wrapper {

    }
  }
}

h2 {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  @include tablet {
    font-size: 18px;
    margin-bottom: 12px;
  }
  @include desktop {
    font-size: 20px;
  }
  @include wide {
    font-size: 19px;
  }
  @include xwide {
    font-size: 22px;
  }
}

ul {
  li {
    font-size: 13px;
    position: relative;
    padding-left: 16px;
    margin-bottom: 16px;
    @include tablet {
      font-size: 16px;
      padding-left: 24px;
      margin-bottom: 20px;
    }
    @include desktop {
      font-size: 18px;
    }
    @include wide {
      font-size: 18px;
    }
    @include xwide {
      font-size: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: '';
      top: 7px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      position: absolute;
      background: #E10783;
      @include tablet {
        top: 10px;
        width: 6px;
        height: 6px;
      }
      @include desktop {
        top: 10px;
        width: 8px;
        height: 8px;
      }
      @include wide {
        top: 8px;
      }
      @include xwide {
        top: 10px;
      }
    }
  }
}

p {
  font-size: 13px;
  @include tablet {
    font-size: 16px;
  }
  @include desktop {
    font-size: 18px;
  }
  @include xwide {
    font-size: 20px;
  }
}

.video {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    z-index: 10;
    cursor: pointer;
    position: relative;
    transition: all .4s ease-in;
    &.isHidden {
      opacity: 0;
      z-index: 0;
      transition: all .4s ease-in;
    }
  }
  video {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}